// TODO: This could be added to the "useAuth" state and no need to pass it anythig. Dreaming haha
//  Github card: https://github.com/alexadark/atsg/issues/35

/**
 * On this app roles are based on crazy code letters and for us being confortable we should do this in another way.
 *
 * - Of course the login access will be manged by user.status or whatever ...
 * - Companies have access to everything (they can be part of Warranty Program or not)
 * - Employees should have access to everything and not been bothered by payments/subscriptions staff!
 * - Recyclers - The same access than companies but more expensive
 * - Sponsor and distributors - Just private area accesss (we will check later!)
 */

// TODO: This will need to change with "subscriptions" and their statuses !

/**
 * Our "own roles"based on crazy legacy backend
 */
export const ROLES = {
  COMPANY: 'COMPANY',
  RECYCLER: 'RECYCLER',
  EMPLOYEE: 'EMPLOYEE',
  SPONSOR: 'SPONSOR',
  DISTRIBUTOR: 'DISTRIBUTOR',
  __DEPRECATED__BULLETINS_ONLY: 'BULLETINS',
}

const DEFAULT_LINK_TEXT = 'GO TO ACCOUNT PAGE'

/**
 * The legacy backend uses this codes, not adding the rest for not easing the hackers
 */
const LETTER_CODES = {
  DROPOUT: '0',
}

/**
 * Our Visible component is going to be able to manage these PERMISSIONS so we can just ask for constants
 * and check on the methods the different ROLE-ACCESS
 */
export const PERMISSIONS = {
  PRIVATE_AREA: 'PRIVATE_AREA',
  MEMBERSHIP_CALLS: 'MEMBERSHIP_CALLS', // includes call-history
  PAY_PER_CASE: 'PAY_PER_CASE',
  TECH_LIBRARY: 'TECH_LIBRARY',
  ALL_MEMBERSHIP: 'ALL_MEMBERSHIP',
  FREE_ACCESS: 'FREE_ACCESS', // otherwise shoul payments!
}

/**
 * Checks if User is "active"
 * Right now is using "legacy", but will be used for braintree
 * //FIXME: Needs to be moved into "firebase" code, just now I am testing legacy.
 * && member_status is awk_gk_members.status
 * @param {object} The current user
 * @returns AN object with {subscriptions, paymentMethods} or containing {error}
 */
const userIsActive = (user) =>
  user?.shopData?.member_status === 'active' ?? false

const getActiveSubsciptions = (subscriptions) => {
  return (
    subscriptions?.filter((subscription) => {
      return subscription && subscription.status === 'Active'
    }) || []
  )
}

const hasActiveBraintreeSubscription = (subscriptions) => {
  return getActiveSubsciptions(subscriptions).length > 0 ? true : false
}

// TODO: Here we will check/call or whatever we need to test against if they have acccess or not to private plans
/**
 * Old users:
 * - Dropouts are "O" in DB, with `ren_date` to null and
 * @returns object - With the status:
 * - {
 *   active: true,
 *   next_payment_cycle: timestamp,
 *   error: {
 *      code: .....,
 *      message: "Some text"
 *   }
 * }
 *
 */
const APP_NAME = 'ATSG'
const DOMAIN_NAME = 'SUBSCRIPTION'

const ERROR_CODES = {
  __DEPRECATED__DROPOUT: `${APP_NAME}_${DOMAIN_NAME}-__DEPRECATED__DROPOUT`,
  __DEPRECATED__EXPIRICY_DATE: `${APP_NAME}_${DOMAIN_NAME}-__DEPRECATED__EXPIRICY_DATE`,
  __DEPRECATED__RENEWAL_DATE: `${APP_NAME}_${DOMAIN_NAME}-__DEPRECATED__RENEWAL_DATE`,
  __FIXME__UNKNOWN: `${APP_NAME}_${DOMAIN_NAME}-__FIXME__UNKNOWN`,
  CUSTOM_BRAINTRE_NON_ACTIVE_SUBSCRIPTION: `${APP_NAME}_${DOMAIN_NAME}-CUSTOM_BRAINTRE_NON_ACTIVE_SUBSCRIPTION`,
}

export const subscriptionState = (user) => {
  // TODO: check if this could be worked to check if the user exists on braintree or not
  const isBraintreeCustomer = user.shopData.payments.subscriptions
  let response = {
    active: false,
    next_payment_cycle: null,
    error: null,
  }

  if (isBraintreeCustomer) {
    const { subscriptions } = user.shopData.payments
    if (!hasActiveBraintreeSubscription(subscriptions)) {
      response = {
        ...response,
        error: {
          code: ERROR_CODES.CUSTOM_BRAINTRE_NON_ACTIVE_SUBSCRIPTION,
          message: `We see that you’ve paid but your account has limited access due to inactive membership status. Please contact us at (800) 245-7722 to resolve this issue. `,
        },
      }
    } else {
      // check what!
      let _next_payment_cycle = null
      try {
        _next_payment_cycle = new Date(
          getActiveSubsciptions(subscriptions)[0].nextBillingDate
        ).getTime()
      } catch (e) {}
      response = {
        ...response,
        active: true,
        next_payment_cycle: _next_payment_cycle,
        error: null,
      }
    }
  } else {
    // const role = getRole(user)
    const code = getCode(user)
    if (code === LETTER_CODES.DROPOUT) {
      response = {
        ...response,
        error: {
          code: ERROR_CODES.__DEPRECATED__DROPOUT,
          message: `Our system says you are previously dropped out as a member. Sorry for any inconvenience, please contact us at (800) 245-7722 for support`,
          linkText: 'GO TO ACCOUNT PAGE',
        },
      }
    } else {
      if (user.shopData.expired) {
        // EXPIRED PAYMENT INFO???
        response = {
          ...response,
          error: {
            code: ERROR_CODES.__DEPRECATED__EXPIRICY_DATE,
            message: `Our system says you have a subscription from an old site but your credit card has expired. Please renew using the link below or contact us at (800) 245-7722 for support.`,
            linkText: 'GO TO ACCOUNT PAGE TO RENEW!',
          },
        }
      }
      if (user.shopData.ren_date) {
        if (user.shopData.ren_date !== '0000-00-00') {
          // DB strange ..
          try {
            const ren_date_timestamp = new Date(
              user.shopData.ren_date
            ).getTime()
            if (ren_date_timestamp < new Date().getTime()) {
              // TODO: throw the error and catch it below!
              response = {
                ...response,
                error: {
                  code: ERROR_CODES.__DEPRECATED__RENEWAL_DATE,
                  message: `Your membership is due for renewal. Please renew using the link below or contact us at (800) 245-7722 for support.`,
                  linkText: 'GO TO ACCOUNT PAGE TO RENEW!',
                },
              }
            } else {
              // success case
              response = {
                ...response,
                active: true,
                next_payment_cycle: ren_date_timestamp,
                error: null,
              }
            }
          } catch (error) {
            response = {
              ...response,
              error: {
                code: ERROR_CODES.__DEPRECATED__RENEWAL_DATE,
                message: `Your membership is due for renewal. Please renew using the link below or contact us at (800) 245-7722 for support.`,
                linkText: 'GO TO ACCOUNT PAGE TO RENEW!',
              },
            }
          }
          // CHECK if renewal date has passed or not!
        } else {
          response = {
            ...response,
            active: true,
            next_payment_cycle: null,
            error: null,
          }
        }
      }
    }
  }
  return response
}

const subscriptionIsActive = (user) => subscriptionState(user)?.active ?? false

export const hasPermission = (user, permission) => {
  if (!PERMISSIONS[permission] || !user) return false
  const isActive = userIsActive(user)
  if (!isActive) return false
  const role = getRole(user)
  const code = getCode(user)

  if (role === ROLES.EMPLOYEE) {
    if (permission === PERMISSIONS.PRIVATE_AREA) {
      // Redundant I know but we might use this if :D
      return isActive
    }
    return Object.values(PERMISSIONS).includes(permission)
  } else if (role === ROLES.COMPANY) {
    if (permission === PERMISSIONS.PRIVATE_AREA) {
      return isActive
    } else if (permission === PERMISSIONS.MEMBERSHIP_CALLS) {
      return subscriptionIsActive(user) && code !== 'B'
    } else if (permission === PERMISSIONS.PAY_PER_CASE) {
      // TODO: if braintree subsribed or subscrbed migrated
      return subscriptionIsActive(user) && code !== 'B'
    } else if (permission === PERMISSIONS.TECH_LIBRARY) {
      // TODO: if braintree subsribed or subscrbed migrated
      return subscriptionIsActive(user)
    } else if (permission === PERMISSIONS.ALL_MEMBERSHIP) {
      return subscriptionIsActive(user) && code !== 'B'
    } else if (permission === PERMISSIONS.FREE_ACCESS) {
      return false
    }
  } else if (role === ROLES.RECYCLER) {
    // console.log("access for recyvlers subscritpion state: ", subscriptionIsActive(user))
    if (permission === PERMISSIONS.PRIVATE_AREA) {
      return isActive
    } else if (permission === PERMISSIONS.MEMBERSHIP_CALLS) {
      return subscriptionIsActive(user) // && code === 'V' // we had an user with 'Y' trying to access this
    } else if (permission === PERMISSIONS.PAY_PER_CASE) {
      // TODO: if braintree subsribed or subscrbed migrated
      return subscriptionIsActive(user) // && (code === "V" || code === "Y")
    } else if (permission === PERMISSIONS.TECH_LIBRARY) {
      // TODO: if braintree subsribed or subscrbed migrated
      return subscriptionIsActive(user)
    } else if (permission === PERMISSIONS.ALL_MEMBERSHIP) {
      return subscriptionIsActive(user) && code !== 'B'
    } else if (permission === PERMISSIONS.FREE_ACCESS) {
      return false
    }
    // Seems that now Sponsors and Distributors can access everything
  } else if (role === ROLES.SPONSOR) {
    if (
      permission === PERMISSIONS.PRIVATE_AREA ||
      permission === PERMISSIONS.TECH_LIBRARY
    ) {
      return isActive
    } else {
      return false
    }
  } else if (role === ROLES.DISTRIBUTOR) {
    if (
      permission === PERMISSIONS.PRIVATE_AREA ||
      permission === PERMISSIONS.TECH_LIBRARY
    ) {
      return isActive
    } else {
      return false
    }
  }
  return false
}

export const hasRole = (user, role) => getRole(user) === role
export const noHasRole = (user, role) => getRole(user) !== role
export const anyRole = (user, roles) =>
  roles.some((role) => role === getRole(user))

export const getCode = (user) => {
  const code = user?.shopData?.code || user?.shopData.sub_num.charAt(0)
  return (code && code.toUpperCase().trim()) || null
}

export const getRole = (user) => {
  if (!user || !user.shopData) return null
  let role = ROLES.DISTRIBUTOR
  const code = getCode(user)
  if (code === 'V' || code === 'Y') {
    role = ROLES.RECYCLER
  } else if (code === 'W' || code === 'S' || code === 'B' || code === 'A' || code === 'F' || code === 'K') {
    role = ROLES.COMPANY
  } else if (code === 'E') {
    role = ROLES.EMPLOYEE
  } else if (code === 'P') {
    role = ROLES.SPONSOR
  } else if (code === 'D') {
    role = ROLES.DISTRIBUTOR
  } else if (code === 'B') {
    role = ROLES.__DEPRECATED__BULLETINS_ONLY
  }
  return role
}

/**
 * New method to simplify the access control.
 * user -> shopData -> code //  const code = user?.shopData?.code || user?.shopData.sub_num.charAt(0)
 * > user?.shopData?.member_status === 'active' ?? false
 * > not braintree customer (the ones in braintree cannot be checked with this ...)
 * >user.shopData.expired
 * */
export const getPermissionDeniedReason = (user, permission) => {
  // Not permission defined. Don't allow access. ✅
  if (!PERMISSIONS[permission] || !user) {
    return {
      hasPermission: false,
      reason: `Permission not defined`,
    }
  }

  // Besides being able to login, user has not active membership. (member_status) ✅
  const isActive = userIsActive(user)
  if (!isActive) {
    return {
      hasPermission: false,
      reason: `Your account has limited access due to inactive user status. Please contact us at (800) 245-7722 to resolve this issue.`,
    }
  }
  const role = getRole(user)
  const code = getCode(user)

  // Employees don't need to check subscriptions or whatever, just their permissions. ✅
  if (role === ROLES.EMPLOYEE) {
    return Object.values(PERMISSIONS).includes(permission)
  }

  const userSubscriptionState = subscriptionState(user)

  // TODO: this might be a problem for "employees"
  if (
    !userSubscriptionState.active &&
    permission !== PERMISSIONS.PRIVATE_AREA
  ) {
    return {
      hasPermission: false,
      reason:
        userSubscriptionState?.error?.message ||
        `Your subscription seems to not be active`,
      linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
    }
  }

  if (role === ROLES.COMPANY) {
    if (
      permission === PERMISSIONS.MEMBERSHIP_CALLS ||
      permission === PERMISSIONS.PAY_PER_CASE ||
      permission === PERMISSIONS.ALL_MEMBERSHIP
    ) {
      const hasPermission = code !== 'B'
      return {
        hasPermission: hasPermission,
        reason: !hasPermission
          ? 'Your membership is limited to Bulletins only. Please contact us at (800) 245-7722 to upgrade your membership now!'
          : null,
        linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
      }
    } else if (permission === PERMISSIONS.TECH_LIBRARY) {
      return {
        hasPermission: userSubscriptionState.active,
        reason: !userSubscriptionState.active
          ? userSubscriptionState.error.message
          : null,
        linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
      }
    }
  } else if (role === ROLES.RECYCLER) {
    if (permission === PERMISSIONS.ALL_MEMBERSHIP) {
      return {
        hasPermission: code !== 'B',
        reason:
          code === 'B'
            ? 'Your membership is limited to Bulletins only. Please contact us at (800) 245-7722 to upgrade your membership now!'
            : null,
        linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
      }
    }
  }
  // Seems that now Sponsors and Distributors can access everything
  else if (role === ROLES.SPONSOR) {
    if (
      permission !== PERMISSIONS.PRIVATE_AREA &&
      permission !== PERMISSIONS.TECH_LIBRARY
    ) {
      return {
        hasPermission: false,
        reason:
          'Your Sponsor Membership includes limited access. Please call (800) 245-7722 if you have any questions.',
        linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
      }
    }
  } else if (role === ROLES.DISTRIBUTOR) {
    if (
      permission !== PERMISSIONS.PRIVATE_AREA &&
      permission !== PERMISSIONS.TECH_LIBRARY
    ) {
      return {
        hasPermission: false,
        reason:
          'Your Distributor Membership includes limited access. Please call (800) 245-7722 if you have any questions.',
        linkText: userSubscriptionState?.error?.linkText || DEFAULT_LINK_TEXT,
      }
    }
  }

  //  Default "active"
  return {
    hasPermission: true,
    reason: null,
    linkText: DEFAULT_LINK_TEXT,
  }
}

const Visible = ({ when, ...props }) => {
  // TODO: the idea is that when can be also a funtion
  const isVisible = when
  return isVisible ? props.children : null
}

export default Visible
